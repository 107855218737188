<div class="row">
  <div class="col-12 col-sm-6 pe-sm-5 pb-5">
    <div class="card card-shadow border-0">
      <div class="card-body">
        <h6 class="mb-4">
          Personal details
        </h6>
        <div class="row mb-2">
          <label class="col-auto col-form-label label-communication text-nowrap me-3">From</label>
          <div class="col">
            <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.from">
          </div>
        </div>
        <ng-template [ngIf]="model?.channel==='Digital' && model?.subChannel==='Email'">
          <div class="row mb-2">
            <label class="col-auto col-form-label label-communication text-nowrap me-3">To</label>
            <div class="col">
              <input type="text" disabled class="form-control-plaintext" [ngModel]="model && model.to && model.to.length>0?model.to[0]:''">
            </div>
          </div>
          <ng-container *ngFor="let to of model?.to; first as isFirst">
            <div *ngIf="!isFirst" class="row">
              <label class="col-auto col-form-label label-communication text-nowrap me-3 invisible">To</label>
              <div class="col">
                <input type="text" disabled class="form-control-plaintext" [ngModel]="to">
              </div>
            </div>
          </ng-container>
          <div class="row mb-2">
            <label class="col-auto col-form-label label-communication text-nowrap me-3">Cc</label>
            <div class="col-auto">
              <input type="text" disabled class="form-control-plaintext" [ngModel]="model && model.cc && model.cc.length>0?model.cc[0]:''">
            </div>
          </div>
          <ng-container *ngFor="let cc of model?.cc; first as isFirst">
            <div *ngIf="!isFirst" class="row">
              <label class="col-auto col-form-label label-communication text-nowrap me-3 invisible">Cc</label>
              <div class="col-auto">
                <input type="text" disabled class="form-control-plaintext" [ngModel]="cc">
              </div>
            </div>
          </ng-container>
          <div class="row mb-2">
            <label class="col-auto col-form-label label-communication text-nowrap me-3">Subject</label>
            <div class="col">
              <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.subject">
            </div>
          </div>
        </ng-template>
        <div class="row mb-2">
          <label class="col-auto col-form-label label-communication text-nowrap me-3">First name</label>
          <div class="col">
            <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.customerFirstName">
          </div>
        </div>
        <div class="row mb-2">
          <label class="col-auto col-form-label label-communication text-nowrap me-3">Last name</label>
          <div class="col">
            <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.customerLastName">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-6 pb-5">
    <div class="card card-shadow border-0">
      <div class="card-body">
        <h6 class="mb-4">
          Details
        </h6>
        <div class="row">
          <label class="col-auto col-form-label label-communication text-nowrap me-3">Work time</label>
          <div class="col">
            <input type="text" disabled class="form-control-plaintext" [ngModel]="(model?.workTime??0) | secondsToTimePeriod">
          </div>
        </div>
        <div class="row">
          <label class="col-auto col-form-label label-communication text-nowrap me-3">Queue time</label>
          <div class="col">
            <input type="text" disabled class="form-control-plaintext" [ngModel]="(model?.queueTime??0) | secondsToTimePeriod">
          </div>
        </div>
        <div class="row">
          <label class="col-auto col-form-label label-communication text-nowrap me-3">Last agent</label>
          <div class="col">
            <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.lastAgent">
            <i *ngIf="model?.isAgentAssigned && model?.lastAgent" class="vi vi-assigned me-3 text-danger-1"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 pb-5">
    <div class="card card-shadow border-0">
      <div class="card-body">
        <h6 class="mb-4">
          Contact
        </h6>
        <div class="row">
          <div class="col">
            <div class="row">
              <div class="col-12 mb-3">
                <label class="form-label">Contact Id</label>
                <input type="text" disabled class="form-control-plaintext" [ngModel]="contactId">
              </div>
              <div class="col-12 mb-3">
                <label class="form-label">Channel</label>
                <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.channel">
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <div class="col-12 mb-3">
                <label class="form-label">Start date</label>
                <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.startDate | date:'short'">
              </div>
              <div class="col-12 mb-3">
                <label class="form-label">End date</label>
                <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.endDate | date:'short'">
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <div class="col-12 mb-3">
                <label class="form-label">State</label>
                <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.state">
              </div>
              <div class="col-12 mb-3">
                <label class="form-label">Direction</label>
                <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.direction">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 pb-4">
	<div class="card card-shadow border-0 mb-4">
		<div class="card-body">
			<h6>
				Dispositions
			</h6>
			<div *ngIf="communication?.contacts?.length === 0; else dispositionTable">
				<span class="sendto-cc-text-style"> <i>There is no disposition notes!</i></span>
			</div>
			<ng-template #dispositionTable>
				<p-table [value]="communication?.contacts!" [scrollable]="true" [resizableColumns]="true" [reorderableColumns]="true" responsiveLayout="scroll">
					<ng-template pTemplate="header">
					  <tr>
						<th style="width: 2rem" pResizableColumn></th>
						<th style="width: 2rem" pResizableColumn>
						  Name
						</th>
						<th style="width: 2rem" pResizableColumn>
						  Agent
						</th>
						<th pResizableColumn>
						  Note
						</th>
					  </tr>
					</ng-template>
					<ng-template pTemplate="body" let-contact let-rowIndex="rowIndex">
					  <tr>
						<td>{{rowIndex + 1}}</td>
						<td>{{contact.disposition.dispositionName}}</td>
						<td>{{contact.agent}}</td>
						<td>{{contact.disposition.notes}}</td>
					  </tr>
					</ng-template>
				  </p-table>
			</ng-template>
		</div>
	</div>
</div>
  <div class="col-12 bg-white">
    <h6 class="ps-5 pt-5">
      {{model?.channel==='Digital'?model?.subChannel:'Chat'}} transcript
    </h6>
  </div>
  <div class="col-12 bg-white">
    <p *ngIf="!model?.transcript || (model?.transcript?.length??0) === 0;else available">
      No transcripts available
    </p>
    <ng-template #available>
      <div *ngFor="let message of model?.transcript" class="row my-2">
        <ng-container *ngIf="message.partyType === 'Client';else agent">
          <div class="col-5 col-sm-4 col-md-3 px-1">
            <div class="rounded bg-lightblue px-2 py-1 mb-1 fw-light">{{ message.timestamp | date:'short' }}</div>
            <div class="rounded bg-lightblue px-2 py-1 mb-1">
              <span class="fst-italic text-success">{{ message.from?message.partyType + ':': message.partyType }}</span>
              <div class="w-100"></div>
              <span class="fw-light">{{ message.from }}</span>
            </div>
            <div *ngIf="message.subject" class="rounded bg-lightblue px-2 py-1 mb-1">
              <span class="fst-italic text-success">Subject:</span>
              <div class="w-100"></div>
              <span class="fw-light">{{ message.subject }}</span>
            </div>
            <div *ngIf="message.to && message.to.length>0" class="rounded bg-lightblue px-2 py-1 mb-1">
              <span class="fst-italic text-success">To:</span>
              <ng-container *ngFor="let to of message.to;last as isLast">
                <div class="w-100"></div>
                <span class="fw-light"> {{to}}{{isLast?'':'; '}}</span>
              </ng-container>
            </div>
            <div *ngIf="message.cc && message.cc.length>0" class="rounded bg-lightblue px-2 py-1 mb-1">
              <span class="fst-italic text-success">Cc:</span>
              <ng-container *ngFor="let cc of message.cc;last as isLast">
                <div class="w-100"></div>
                <span class="fw-light"> {{cc}}{{isLast?'':'; '}}</span>
              </ng-container>
            </div>
            <div *ngIf="message.attachments && message.attachments.length>0" class="rounded bg-lightblue px-2 py-1 mb-1">
              <span class="fst-italic text-success">Attachments</span>
              <ng-container *ngFor="let attachment of message.attachments">
                <div class="col-auto pe-2 pb-3">
                  <button type="button" class="btn btn-sm btn-secondary">
                    {{attachment}}
                    <i (click)="processAttachment(message.emailGuid,attachment,'download',$event)" class="vi vi-file-download-dark ms-3"></i>
                    <i (click)="processAttachment(message.emailGuid,attachment,'open',$event)" class="vi vi-open-in-new ms-3"></i>
                  </button>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="col">
            <p class="vrc-chat-text mb-0 rounded bg-success-1 px-2 py-1 h-100">
              <ng-container *ngIf="model?.subChannel==='Email';else other">
                <iframe class="w-100" style="height: 20rem;" [srcdoc]="(message?.text??'') | htmlSanitizer"></iframe></ng-container>
              <ng-template #other>
                <ng-container *ngFor="let c of message.text??'' | chatText">
                  {{c}}<br>
                </ng-container>
              </ng-template>
            </p>
          </div>
        </ng-container>
        <ng-template #agent>
          <div class="col">
            <p class="vrc-chat-text mb-0 rounded bg-info px-2 py-1 h-100">
              <ng-container *ngIf="model?.subChannel==='Email';else other">
                <iframe class="w-100" style="height: 20rem;" [srcdoc]="(message?.text??'') | htmlSanitizer"></iframe></ng-container>
              <ng-template #other>
                <ng-container *ngFor="let c of message.text??'' | chatText">
                  {{c}}<br>
                </ng-container>
              </ng-template>
            </p>
          </div>
          <div class="col-5 col-sm-4 col-md-3 px-1">
            <div class="rounded bg-lightblue px-2 py-1 mb-1 fw-light">{{ message.timestamp | date:'short' }}</div>
            <div class="rounded bg-lightblue px-2 py-1 mb-1">
              <span class="fst-italic text-success">{{ message.agent?message.partyType + ':': message.partyType }}</span>
              <div class="w-100"></div>
              <span class="fw-light">{{ message.agent }}</span>
            </div>
            <div *ngIf="message.subject" class="rounded bg-lightblue px-2 py-1 mb-1">
              <span class="fst-italic text-success">Subject:</span>
              <div class="w-100"></div>
              <span class="fw-light">{{ message.subject }}</span>
            </div>
            <div *ngIf="message.to && message.to.length>0" class="rounded bg-lightblue px-2 py-1 mb-1">
              <span class="fst-italic text-success">To:</span>
              <ng-container *ngFor="let to of message.to;last as isLast">
                <div class="w-100"></div>
                <span class="fw-light"> {{to}}{{isLast?'':'; '}}</span>
              </ng-container>
            </div>
            <div *ngIf="message.cc && message.cc.length>0" class="rounded bg-lightblue px-2 py-1 mb-1">
              <span class="fst-italic text-success">Cc:</span>
              <ng-container *ngFor="let cc of message.cc;last as isLast">
                <div class="w-100"></div>
                <span class="fw-light"> {{cc}}{{isLast?'':'; '}}</span>
              </ng-container>
            </div>
            <div *ngIf="message.attachments && message.attachments.length>0" class="rounded bg-lightblue px-2 py-1 mb-1">
              <span class="fst-italic text-success">Attachments</span>
              <ng-container *ngFor="let attachment of message.attachments">
                <div class="col-auto pe-2 pb-3">
                  <button type="button" class="btn btn-sm btn-secondary">
                    {{attachment}}
                    <i (click)="processAttachment(message.emailGuid,attachment,'download',$event)" class="vi vi-file-download-dark ms-3"></i>
                    <i (click)="processAttachment(message.emailGuid,attachment,'open',$event)" class="vi vi-open-in-new ms-3"></i>
                  </button>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-template>
      </div>
    </ng-template>
  </div>
</div>