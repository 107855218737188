<vrc-account-notes *ngIf="accountNotesVisible" [(visible)]="accountNotesVisible" />

<div class="row sticky-top">
  <div *ngIf="st.isEditOn" [class]="'col-12 py-5 ' + theme">
    <div class="row justify-content-end">
      <div class="col-auto">
        <div class="btn-group me-5">
          <button (click)="cancel()" type="button" class="btn btn-secondary text-uppercase">
            Cancel
            <i class="vi vi-times-circle ms-5"></i>
          </button>
        </div>
        <div class="btn-group me-5">
          <button [disabled]="isSaving" (click)="validateEmailOrPhone(false)" type="button" class="btn btn-primary text-uppercase">
            {{st.model?.id? 'Save':'Create account'}}
            <i class="vi vi-check-circle ms-5"></i>
          </button>
        </div>
        <div *ngIf="!st.model?.id" class="btn-group me-5">
          <button [disabled]="isSaving" (click)="validateEmailOrPhone(true)" type="button" class="btn btn-primary text-uppercase">
            <span *ngIf="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Create account and loyalty
            <i class="vi vi-check-circle ms-5"></i>
          </button>
        </div>
        <div *ngIf="showRefreshAccount" class="btn-group me-5">
          <button (click)="refresh()" type="button" class="btn btn-primary">
            Refresh
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 bg-secondary border-bottom border-gray-50 ms-0 py-4">
    <div class="row">
      <div class="col pt-4">
        <div *ngIf="!isNewOn" class="row">
          <div class="col-auto me-3">
            <h5 class="my-auto py-0 text-nowrap ps-5">
              {{st.model?.firstName}} {{st.model?.lastName}}
            </h5>
          </div>
          <div class="col me-3">
            <h6 class="my-0 py-0">
              <span *ngIf="st.model?.additionalInformation?.isVip" class="badge pill badge-guest bg-gray-50 text-gray-700 me-2">Vip guest</span>
              <span *ngIf="st.model?.additionalInformation?.isTopGuest" class="badge pill badge-guest bg-gray-50 text-gray-700 me-2">Top guest</span>
              <span *ngIf="st.model?.additionalInformation?.isBlacklisted" class="badge pill badge-guest bg-danger-1 text-gray-700 me-2">Blacklisted guest</span>
            </h6>
          </div>
        </div>
      </div>
      <div class="col-auto">
        <div class="input-group input-group-lg me-5 my-0">
          <input style="width: 7.5rem;" [(ngModel)]="offerId" type="text" class="form-control" placeholder="Offer number" aria-label="Offer number">
          <button [disabled]="!offerId || !this.st.model?.id || st.model?.additionalInformation?.gdprDelete" (click)="getOffer()" type="button" class="btn btn-primary">
            <i class="vi vi-local-offer"></i>
          </button>
        </div>
      </div>
      <div *ngIf="!st.isEditOn" class="col-auto">
        <div class="btn-toolbar my-0 ms-5" role="toolbar" aria-label="Edit toolbar">
          <div *ngIf="st.model?.id && !((st.model?.loyaltiesList??[]) | selectLoyaltyProgram:st.ValamartistProgram:true)" class="btn-group me-5">
            <button [disabled]="isSaving || st.model?.additionalInformation?.gdprDelete" (click)="createLoyalty()" type="button" class="btn btn-lg btn-primary">
              Create loyalty
              <i class="vi vi-person-add-alt ms-5" [class.fa-beat-fade]="isSaving"></i>
            </button>
          </div>
          <div class="btn-group me-5">
            <button [disabled]="st.model?.additionalInformation?.gdprDelete" (click)="edit()" type="button" class="btn btn-lg btn-primary">
              Edit
              <i class="vi vi-editpen ms-5" [class.fa-beat-fade]="isSaving"></i>
            </button>
          </div>
          <div class="btn-group me-5">
            <button [disabled]="st.model?.additionalInformation?.gdprDelete" (click)="openAccountNotes()" type="button" class="btn btn-lg btn-primary">
              Notes
              <i class="pi pi-book ms-5"></i>
              <span *ngIf="accountNotesCounter" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                {{accountNotesCounter}}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 bg-secondary border-bottom border-gray-50 ms-0">
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link text-uppercase text-gray-700" [routerLink]="'summary'" routerLinkActive="active">Summary</a>
      </li>
      <li class="nav-item">
        <a class="nav-link text-uppercase text-gray-700" [routerLink]="'details'" routerLinkActive="active">Contact Details</a>
      </li>
      <li class="nav-item">
        <a class="nav-link text-uppercase text-gray-700" [routerLink]="'communication'" routerLinkActive="active">Communication</a>
      </li>
      <li class="nav-item">
        <a class="nav-link text-uppercase text-gray-700" [routerLink]="'history'" routerLinkActive="active">Communication History</a>
      </li>
      <li class="nav-item" *ngIf="!isProduction()">
        <a class="nav-link text-uppercase text-gray-700" [routerLink]="'thread'" routerLinkActive="active">Communication Thread</a>
      </li>
      <li class="nav-item">
        <a class="nav-link text-uppercase text-gray-700" [routerLink]="'reservations'" routerLinkActive="active">Reservations</a>
      </li>
      <li class="nav-item">
        <a class="nav-link text-uppercase text-gray-700" [routerLink]="'campaigns'" routerLinkActive="active">Campaigns</a>
      </li>
      <li class="nav-item" *ngIf="st.selectedLoyaltyNumber && (st.model?.loyaltiesList | isSelectedLoyaltyProgram: st.ValamartistProgram:true:trigger)">
        <a class="nav-link text-uppercase text-gray-700" [routerLink]="'loyalty' + '/' + this.st.selectedLoyaltyNumber" routerLinkActive="active">Loyalty</a>
      </li>
      <li class="nav-item" *ngIf="st.selectedValamartistNumber && (st.model?.loyaltiesList | isSelectedLoyaltyProgram: st.ValamartistProgram:false:trigger)">
        <a class="nav-link text-uppercase text-gray-700" [routerLink]="'valamartist' + '/' + this.st.selectedValamartistNumber" routerLinkActive="active">ValamArtist</a>
      </li>
    </ul>
  </div>
</div>
<div class="my-0 align-top bg-lighter pt-0">
  <router-outlet></router-outlet>
</div>

<ng-template valModal="sm" [(valmVisible)]="showModal" [valmCloseOnBackdrop]="false">
  <div class="modal-header">
    <h5 class="modal-title">Contacts validation</h5>
    <button type="button" class="btn-close" (click)="showModal=false"></button>
  </div>
  <div class="modal-body">
    <div class="mb-4">
      <h6>
        {{dialogTitle}}
      </h6>
    </div>
    <div *ngIf="phoneValidationDialogDescription" class="mb-4">
      <span>Phone validation response: </span>
      <span class="text-danger">{{phoneValidationDialogDescription}}</span>
    </div>
    <div *ngIf="emailValidationDialogDescription" class="mb-4">
      <span>Email validation response: </span>
      <span class="text-danger">{{emailValidationDialogDescription}}</span>
    </div>
  </div>

  <div class="modal-footer m-0">
    <button (click)="showModal=false;closeModal()" type="button" class="btn btn-secondary text-uppercase me-5">
      Cancel
      <i class="vi vi-times-circle ms-3"></i>
    </button>
    <button (click)="saveAccount();showModal=false" type="button" class="btn btn-primary text-uppercase">
      Confirm
      <i class="vi vi-check-circle ms-3"></i>
    </button>
  </div>
</ng-template>

<ng-template valModal="sm" [(valmVisible)]="showDuplicatePrimaryModal" [valmCloseOnBackdrop]="false">
  <div class="modal-header">
    <h5 class="modal-title text-uppercase">Existing account error</h5>
    <button type="button" class="btn-close" (click)="showDuplicatePrimaryModal=false"></button>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger" role="alert">
      {{duplicatePrimaryModalError}}
    </div>
    <button (click)="openExistingAccountInNewWindow();showDuplicatePrimaryModal=false" type="button" class="btn btn-primary shadow-sm h-auto me-2 mb-2">
      Open existing accounts in new windows
      <i class="vi vi-open-in-new ms-4"></i>
    </button>
    <button *ngIf="!st.model?.id" (click)="mergeExistingAccount();showDuplicatePrimaryModal=false" type="button"
      class="btn btn-info shadow-sm h-auto float-end mb-2">
      Merge new account to existing account
    </button>
  </div>

  <div class="modal-footer m-0">
    <button (click)="showDuplicatePrimaryModal=false" type="button" class="btn btn-secondary shadow-sm">
      Close
    </button>
  </div>
</ng-template>